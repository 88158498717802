export default {
    name: 'promotion-9',
    data(){
        return {

        }
    },
    computed:{

    },
    created(){

    },
    methods: {

    },
    destroyed(){

    }
}
